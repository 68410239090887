<template>
  <div class="curriculumJudgement">
    <div class="minw homeDivCss">
      <div class="bottom">
        <div class="three">
          <!-- 一级数据 -->
          <div class="firstBox">
              <p class="firstItem" v-for="(item,index) in firstData" @click="oneClick(index)" :class="{activeFirstItem:index == oneIndex}">{{ item.name}}</p>
          </div>
          <!-- 二级数据 -->
          <div class="secondBox">
              <p class="secondItem" v-for="(item,index) in secondData" @click="twoClick(index)" :class="{activeSecondItem:index == twoIndex}">{{ item.name}}</p>
          </div>
          <!-- 三级数据 -->
           <div class="thirdBox">
              <p class="thirdItem" v-for="(item,index) in thirdData" @click="threeClick(index)" :class="{activeThirdItem:index == threeIndex}">{{ item.name}}</p>
          </div>
          <!-- 搜索框以及提示信息 -->
          <div class="wrapbox">
            <div class="onebox">
              全国第四、五轮学科评估
            </div>
            <img class="resultImg" src="../../assets/imgs/curriculumJudgementresult.png" alt="">
            <div class="twobox">
              <div class="searchInput">
                <el-input v-model="searchModel" placeholder="请输入学校名称" @keyup.enter.native=" " clearable></el-input>
                <div class="divicon" @click="handClick">
                  <i class="el-icon-search" style="color: white;"></i>
                </div>
              </div>
              <div style="font-size: 14px;color: #918a8a;margin-top: 10px;text-align: left;">(数据来源网络，仅供参考)</div>
              <div style="font-size: 14px;color: #918a8a;margin-top: 10px;text-align: left;">(第五轮数据教育部尚未公布，仅做参考)</div>

            </div>
          </div>
          <!-- 院校评估数据 -->
          <div class="right" v-loading="loading">
            <div class="labelTitle">
              <div class="schoolname">学校名称</div>
              <div class="schoolcode">学校代码</div>
              <div class="pinggufirst">第四轮评估</div>
              <div class="pinggusecond">第五轮评估</div>
            </div>
            <div class="threeDiv scrollBoxCss" :class="tableData.length < 16 ? 'noScroll' : ''">
              <div class="threeItem" v-for="(item, index) in tableData" :key="index">
                <div class="schoolname">{{ item.collegeName }}</div>
                <div class="schoolcode">
                  {{ item.collegeCode }}
                </div>
                <div class="pinggufirst">
                  <p class="PGresult" :style="{ background: item.bgColorFour }"> {{ item.grade }}</p>
                </div>
                <div class="pinggusecond">
                  <p class="PGresult" :style="{ background: item.bgColorFive }"> {{ item.gradeNew || '--' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { evaluationCategories, queryByMajorAndSubject, queryBySubject,queryBySubjectThree } from "@/api/homeIndex.js";

export default {
  name: 'curriculumJudgement',
  data() {
    return {
      oneIndex: 0,
      twoIndex: 0,
      threeIndex: 0,
      loading: false,
      firstData: [],
      secondData: [

      ],
      thirdData:[


      ],
      tableData: [],
      searchModel: ''
    }
  },
  created() {
    this.getevaluationCategories()
  },
  watch: {
    'searchModel': function (n, o) {
      if (n == '') {
        this.getevaluationCategories()
      }
    }
  },
  methods: {
    // 查询一级
    getevaluationCategories() {
      evaluationCategories().then(res => {
        if (res.code == 200) {
          this.firstData = res.data.map((item,index)=>{
            return {name:item,index}
          })
          this.oneIndex = 0
          this.oneClick(this.oneIndex)
        }
      })
    },
    // 一级点击的时候
    oneClick(index) {
        this.oneIndex =index;
        this.twoIndex = 0;
        this.getSubject()
    },
    // 根据一级，查询二级
    getSubject() {
      const self = this;
      queryBySubject({ category: this.firstData[this.oneIndex].name }).then(res => {
        if (res.code == 200) {
          self.secondData = res.data.map((item,index) => {
            return { name:item,index }
          });
          self.twoClick(this.twoIndex)
        }
      })
    },
        // 根据二级查询三级
    twoClick(index) {
      this.twoIndex = index
       this.threeIndex = 0;
      this.getThirdData()
    },
        // 根据一级、二级查询三级
    getThirdData(){
      let obj = {
        category:this.firstData[this.oneIndex].name,
        majorCategory:this.secondData[this.twoIndex].name
      }
      queryBySubjectThree(obj).then(res=>{
          this.thirdData = res.data.map((item,index) => {
          return { name:item,index }
        });
        this.threeClick(this.threeIndex)
      })
    },

    // 三级点击的时候，查询数据
    threeClick(index){
        this.threeIndex = index
        this.getTableData()
    },
    handClick() {
      let arry = []
      this.tableData.forEach(item => {

        if (item.collegeName.includes(this.searchModel)) {
          let bgColorFour = '';

       if(item.grade){
            if (item.grade.includes('A')) {
              bgColorFour = '#30cab2'
            } else if (item.grade.includes('B')) {
              bgColorFour = '#3684e6'
            } else if (item.grade.includes('C')) {
              bgColorFour = '#ffb522'
            } else if (item.grade.includes('D')) {
              bgColorFour = '#ff2251'
            }else{
                 bgColorFour = '#ccc'
            }
          }
          let bgColorFive = ''
           // 第五论学科评估字段
          if(item.gradeNew){
            if (item.gradeNew.includes('A')) {
              bgColorFive = '#30cab2'
            } else if (item.gradeNew.includes('B')) {
              bgColorFive = '#3684e6'
            } else if (item.gradeNew.includes('C')) {
              bgColorFive = '#ffb522'
            } else if (item.gradeNew.includes('D')) {
              bgColorFive = '#ff2251'
            }else{
                 bgColorFive = '#ccc'
            }
          }else{
            bgColorFive = '#ccc'
          }

          arry.push({ ...item, bgColorFour: bgColorFour,bgColorFive:bgColorFive})

        }
      })
      this.tableData = arry
    },
    // 查询列表数据
    getTableData(){
      let obj = {
        category:this.firstData[this.oneIndex].name,
        majorCategory:this.secondData[this.twoIndex].name,
        subjectName:this.thirdData[this.threeIndex].name
      }
      this.loading = true;
      queryByMajorAndSubject(obj).then(res=>{
         this.loading = false
            this.tableData =   res.data.map(item => {
          let bgColorFour = '';
          // 第四论学科评估字段
          if(item.grade){
            if (item.grade.includes('A')) {
              bgColorFour = '#30cab2'
            } else if (item.grade.includes('B')) {
              bgColorFour = '#3684e6'
            } else if (item.grade.includes('C')) {
              bgColorFour = '#ffb522'
            } else if (item.grade.includes('D')) {
              bgColorFour = '#ff2251'
            }else{
                 bgColorFour = '#ccc'
            }
          }
          let bgColorFive = ''
           // 第五论学科评估字段
          if(item.gradeNew){
            if (item.gradeNew.includes('A')) {
              bgColorFive = '#30cab2'
            } else if (item.gradeNew.includes('B')) {
              bgColorFive = '#3684e6'
            } else if (item.gradeNew.includes('C')) {
              bgColorFive = '#ffb522'
            } else if (item.gradeNew.includes('D')) {
              bgColorFive = '#ff2251'
            }else{
                 bgColorFive = '#ccc'
            }
          }else{
            bgColorFive = '#ccc'
          }
          return { ...item, bgColorFour: bgColorFour,bgColorFive:bgColorFive}
      })

      })
    }

  }

}
</script>

<style lang="scss" scoped>
.curriculumJudgement {
  user-select: none;
  // background: rgb(252, 251, 249);
  // padding-bottom: 100px;

  .bottom {
    padding: 0px;
    position: relative;

    .title {
      width: 100%;
      height: 75px;
      padding: 5px 0;
      text-align: center;
      font-size: 25px;
      font-weight: 800;
      border-bottom: 1px solid #ccc;
    }

    .msg {
      margin-top: 15px;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
      color: #30C9B2
    }

    .three {
      // display: flex;
      height: calc(1100px - 86px);

      .left,
      .middle {
        // width: 90%;
        // overflow-x: scroll;
        // white-space: nowrap;
        // margin: auto;
        // padding-bottom: 10px;
      }

      .left::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      .left::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        background-color: #30C9B2;
        height: 10px;
        border-radius: 10px;
      }

      .left::-webkit-scrollbar-track {
        background-color: #ccc;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
      }

      .middle::-webkit-scrollbar {
        display: none;
      }

      .noScroll::-webkit-scrollbar {
        display: none;
      }

      .left {
          width: 100%;

        .oneItem {
         cursor: pointer;
    display: inline-block;
    margin-right: 9px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #5f5f5f;
    box-sizing: border-box;
    padding: 3px 8px;
        }

        .oneItemActive {
          background: #12B098;
          color: white;
          border-radius: 10px;
        }
      }

      .middle {
        width: 100%;
        margin: 20px 0 20px 0;
        padding-left:60px ;
        box-sizing: border-box;

        // background: rgb(244, 244, 244);
        .twoItem {
          display: inline-block;
          vertical-align: top;
          font-size: 17px;
          color: #5F5F5F;
          padding-bottom: 4px;
          margin-right: 15px;
        }

        .twoItemActive {
          border-bottom: 3px solid #12B098;
        }
      }

      .wrapbox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .onebox {
          width: 210px;
          font-size: 28px;
          color: rgb(18, 176, 152);
          line-height: 42px;
        }

        .resultImg {
          margin-left: 41px;
        }

        .twobox {
          margin-left: auto;
        }
      }

      .right {
        width: 100%;
        position: relative;
        height: 100%;

        .schoolname {
          flex: 7;
        }

        .schoolcode {
          flex: 7;

        }

        .pinggufirst {
          // flex: 3;
          width:25%
        }

        .pinggusecond {
          // flex: 3;
           width:25%
        }

        .PGresult {
          width: 30px !important;
          height: 30px;
          border-radius: 50%;
          line-height: 30px;
          margin: 5px auto;
          color: white;
          font-size: 15px;
        }

        .labelTitle {
          display: flex;
          width: 100%;
          background: #30C9B2;
          color: white;
          align-items: center;
          height: 52px;
          position: absolute;
          font-size: 17px;
          border-radius: 20px 20px 0 0;

          div {
            text-align: center;
            width: 25%;
          }
        }

        .threeDiv {
          position: absolute;
          top: 52px;
          height: 631px;
          overflow-y: scroll;
          width: 100%;
        }

        .threeItem {
          display: flex;
          align-items: center;
          // background: rgb(246 246 246);
          height: 40px;
          line-height: 40px;
          border: 1px solid #f5f7fa;
          font-size: 16px;
          transition: all 0.5s;

          div {
            border: 1px solid rgb(237, 235, 235);
            height: 41px;
            text-align: center;
          }
        }

        .threeItem:hover {
          background: #f5f7fa;
        }
      }
    }
  }
}
.firstBox{
  display: flex;
  box-sizing: border-box;
  margin-left: 10px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #12b098;
}
.firstItem{
   width: 10%;
  //  margin: 0 3px;
   text-align: center;
   padding-bottom: 5px;
   background: #f2f2f2;

}
.activeFirstItem{
    // border-bottom: 2px solid #30C9B2;
   background: #12b098;
  color:white
}
.secondBox{
 padding-left: 10px;
 padding-bottom: 5px;
  box-sizing: border-box;
  margin: 15px 0 0 0;
  border-bottom: 1px solid #e3e3e3;
}
.secondItem{
  cursor: pointer;
    display: inline-block;
    margin-right: 9px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #5F5F5F;
    box-sizing: border-box;
    padding: 3px 8px;
    height: 30px;
    line-height: 24px;

}
.activeSecondItem{
  border-radius: 8px;

    color: #12B098;
    background: white;
    border: 1px solid #12B098;
}
.thirdBox{
    padding-left: 10px;
  box-sizing: border-box;
  margin: 15px 0 0 0;
}
.thirdItem{
   cursor: pointer;
    display: inline-block;
    margin-right: 9px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #5F5F5F;
    box-sizing: border-box;
    padding: 3px 8px;
    height: 30px;
    line-height: 24px;
}
.activeThirdItem{
  background: #12B098;
    border-radius: 8px;
    color: white;
}

</style>
